import React from 'react';

import Body from 'gatsby-rage-ppc-theme/src/gatsby-core-theme/components/pages/body/index';

const cookieConsentBody = (
  <>
    <p>
      Denne hjemmeside bruger cookies til at sikre, at du får den bedste oplevelse på vores
      hjemmeside <br /> Du kan også skifte dine cookies-konfigurationer&nbsp;
      <a href="/cookies"> her</a>.
      <br />
      <a className="cc-link cookie-consent-gtm" href="/persondatapolitik" target="_blank">
        Lær mere
      </a>
    </p>
  </>
);

export default (props) => {
  return (
    <Body
      {...props}
      cookieConsentBody={cookieConsentBody}
      cookieConsentBtn="Forstået"
    />
  );
};
